import React, { useMemo } from 'react';
import { useCounter } from '@/hooks/useCounter';
import { Button, Checkbox, Input } from '@ford/ford-ui-components';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';

const Main = () => {
  const { count, increment } = useCounter();

  const env: 'development' | 'production' = useMemo(() => {
    return import.meta.env.VITE_TEST || process.env.NODE_ENV === 'test' ? 'development' : 'production';
  }, []);

  return (
    <>
    </>
  );
};

export default Main;
