import BaseApiService from '@/lib/api/baseService';
import axios from 'axios';

interface SurveyData {
  // System/non-UI fields
  show_id: number;
  start_date: string;
  end_date: string;
  specialist_id: string | null;
  team_id: number;
  survey_date: Date;
  device_survey_guid: string;
  device_id: string;
  survey_type: string;
  app_version: string;
  abandoned: number;
  start_time: Date;
  end_time: Date;

  // Personal info
  first_name: string;
  last_name: string;
  business_name: string | null;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  country_code: string;
  zip_code: string;

  // Contact info
  phone: string | null;
  email: string;
  email_opt_in: number;
  accepts_sms: number;
  gender: string | null;
  age_bracket: string | null;

  // Other fields
  vehicle_driven_most_make_id: number | null;
  in_market_timing: string | null;
  personal_or_business: string | null;
  language: string;
  industry: string | null;

  // Signature/Waiver
  waiver_type: string;
  signature: string | null;
  minor_signature: string | null;

  // Vehicles of interest
  vehicles_of_interest: number[];

  // Custom data
  custom_data: string; // JSON stringified object
}

// Increase timeout to 30 seconds
const api = axios.create({
  baseURL: 'https://api.latitudewebservices.com/v1',
  timeout: 30000, // 30 seconds
  headers: {
    'Content-Type': 'application/json'
  }
});

class SurveyService extends BaseApiService {
  constructor() {
    super('/survey');
  }

  async saveMicrosite(data: SurveyData): Promise<any> {
    return this.post('/insertMicrosite', data);
  }
}

export default new SurveyService(); 