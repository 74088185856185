import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '@/contexts/LanguageContext';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/hooks/useTranslation';
import QRCodeStyling from 'qr-code-styling';
import FordLogo from '@/assets/images/Ford_Signature_Blue.png';
import LincolnLogo from '@/assets/images/Lincoln_Logo_Black.png';

interface ThankYouState {
  team_id: number;
  showData: {
    teamID: number;
    isLincoln: boolean;
    isCanadian: boolean;
    showName: string;
  };
  result: {
    pin?: string;
    waiverSigned?: boolean;
    minorsCount?: number;
  };
}

const ThankYou = () => {
  const location = useLocation();
  useLanguage();
  const { t } = useTranslation();
  const state = location.state as ThankYouState;
  const qrCode = useRef<any>(null);

  useEffect(() => {
    // Initialize QR code instance
    if (!state?.result?.pin) return;

    const qrString = `${state.result.pin}-${state.result.waiverSigned ? `${state.result.minorsCount}m` : '0'}`;
    const logo = state.showData?.isLincoln ? LincolnLogo : FordLogo;
    
    // Create a new Image object to preload the logo
    const logoImg = new Image();
    logoImg.src = logo;
    
    logoImg.onload = () => {
      qrCode.current = new QRCodeStyling({
        width: 300,
        height: 300,
        data: qrString,
        dotsOptions: {
          color: "#000000",
          type: "rounded"
        },
        cornersSquareOptions: {
          color: state.showData?.isLincoln ? "#000000" : "#00095B",
          type: "extra-rounded"
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 4,
          hideBackgroundDots: true,
          imageSize: 0.4
        },
        image: logoImg.src,
        qrOptions: {
          errorCorrectionLevel: 'H',
          typeNumber: 0,
          mode: 'Byte'
        }
      });

      const canvas = document.getElementById('qr-canvas');
      if (canvas) {
        canvas.innerHTML = '';
        qrCode.current.append(canvas);
      }
    };
  }, [state]);

  if (!state) {
    return (
      <div className="h-screen flex items-center justify-center p-4">
        <Card className="w-full max-w-lg">
          <CardHeader>
            <CardTitle>{t('thankYouOptOut')}</CardTitle>
          </CardHeader>
        </Card>
      </div>
    );
  }

  const { result, showData } = state;
  const isFord = showData?.teamID === 5;

  return (
    <div className="h-screen flex items-center justify-center p-4">
      <Card className="w-full max-w-lg">
        <CardHeader className="text-center">
          <CardTitle className={`${isFord ? 'text-ford-blue' : 'text-lincoln-black'} text-center`}>
            {t(isFord ? 'thankYouRegisterFord' : 'thankYouRegisterLincoln')}
          </CardTitle>
        </CardHeader>
        
        {result.pin && (
          <CardContent className="flex flex-col items-center text-center">
            <p className="mb-4">{t('qrCodeInstructions')}</p>
            <div className="bg-white p-6 rounded">
              <div id="qr-canvas"></div>
            </div>
            <p className="font-mono mt-2">{t('pin')}: {result.pin}</p>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default ThankYou;
