import React from 'react';
import { RouteObject } from 'react-router-dom';
import Layout from '@/pages/layout';
import { ShowProvider } from '@/contexts/ShowContext';

import Main from '@/pages/main';

import Survey from '@/pages/survey';
import ThankYou from '@/pages/thank-you';
import ErrorPage from '@/pages/oops';

import NotFound from '@/pages/NotFound';

export default [
  {
    path: '/',
    element: (
      <ShowProvider>
        <Layout />
      </ShowProvider>
    ),
    children: [{ index: true, element: <Main /> }],
  },
  {
    path: '/survey/:hashed_show_id/:specialist_id/:timestamp',
    element: (
      <ShowProvider>
        <Layout />
      </ShowProvider>
    ),
    children: [{ index: true, element: <Survey /> }],
  },
  {
    path: '/success',
    element: (
      <ShowProvider>
        <Layout />
      </ShowProvider>
    ),
    children: [{ index: true, element: <ThankYou /> }],
  },
  {
    path: '/oops',
    element: <ErrorPage />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
] as RouteObject[];
