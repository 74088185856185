import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as Sentry from "@sentry/react";

const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': import.meta.env.VITE_API_KEY
  },
};

const client = axios.create(config);

// Request interceptor
client.interceptors.request.use(
  (config) => {
    // You can add auth tokens here if needed
    return config;
  },
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => response.data,
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default client;