import moment from 'moment-timezone';

export interface ShowRequestPayload {
  hashed_show_id: string;
  specialist_id: number;
  timestamp: string;
  qrTime: string;
}

const globalShowIDs = [23, 15, 56, 43];

export interface ShowData {
  show_id: number;
  show_type: string;
  team_id: number;
  season_id: number;
  master_show_id: number;
  canadian_show: number;
  show_name: string;
  start_date: string;
  end_date: string;
  waiver: string | null;
  minor_waiver: string | null;
  status: string;
  n: string;
}

interface ShowType {
  rideDrive: boolean;
  simulator: boolean;
  greenscreen: boolean;
  posterMaker: boolean;
}

interface WaiverText {
  en: string;
  es?: string;
  fr?: string;
}

export class AutoShow {
  showID: number;
  showType: ShowType;
  teamID: number;
  seasonID: number;
  masterShowID: number;
  canadianShow: number;
  showName: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  waiver: WaiverText | null;
  minorWaiver: WaiverText | null;
  status: string;
  n: string;

  constructor(data: ShowData) {
    this.showID = data.show_id;
    const rawShowType = data.show_type ? JSON.parse(data.show_type) : {};
    this.showType = {
      rideDrive: rawShowType?.rideDrive === 1,
      simulator: rawShowType?.simulator === 1,
      greenscreen: rawShowType?.greenscreen === 1,
      posterMaker: rawShowType?.posterMaker === 1
    };
    this.teamID = data.team_id;
    this.seasonID = data.season_id;
    this.masterShowID = data.master_show_id;
    this.canadianShow = data.canadian_show;
    this.showName = data.show_name;
    this.startDate = moment(data.start_date).startOf('day');
    this.endDate = moment(data.end_date).endOf('day');
    this.waiver = data.waiver ? JSON.parse(data.waiver) : null;
    this.minorWaiver = data.minor_waiver ? JSON.parse(data.minor_waiver) : null;
    this.status = data.status;
    this.n = data.n;
  }

  get isLincoln(): boolean {
    return this.teamID === 1;
  }

  get isFord(): boolean {
    return this.teamID === 5;
  }

  get isCanadian(): boolean {
    return this.canadianShow === 1;
  }

  get isGlobalShow(): boolean {
    return globalShowIDs.includes(this.masterShowID);
  }

  get isActive(): boolean {
    const now = moment();
    return this.status === 'active' && 
           now.isSameOrAfter(this.startDate) && 
           now.isSameOrBefore(this.endDate);
  }

  get hasRideDrive(): boolean {
    return this.showType.rideDrive;
  }

  get hasSimulator(): boolean {
    return this.showType.simulator;
  }

  get hasGreenscreen(): boolean {
    return this.showType.greenscreen;
  }

  get hasPosterMaker(): boolean {
    return this.showType.posterMaker;
  }
}