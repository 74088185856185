import BaseApiService from '@/lib/api/baseService';
import type { ShowRequestPayload, ShowData } from './types/survey';
import { AutoShow } from './types/survey';

class ShowService extends BaseApiService {
  constructor() {
    super('/show');
  }

  async fetchShow(payload: ShowRequestPayload): Promise<AutoShow> {
    const response = await this.post<ShowData>('/fetch', payload);
    return new AutoShow(response);
  }
}

export default new ShowService(); 