import { createContext, useContext, useEffect, useState } from 'react';
import { AutoShow } from '@/services/types/survey';
import { AxiosError } from 'axios';

interface ShowContextType {
  showData: AutoShow | null;
  loading: boolean;
  error: AxiosError | null;
  setShowData: (data: AutoShow | null) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: AxiosError | null) => void;
}

const ShowContext = createContext<ShowContextType | undefined>(undefined);

export const ShowProvider = ({ children }: { children: React.ReactNode }) => {
  const [showData, setShowData] = useState<AutoShow | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError | null>(null);

  // Clear context on unmount
  useEffect(() => {
    return () => {
      setShowData(null);
      setLoading(false);
      setError(null);
    };
  }, []);

  return (
    <ShowContext.Provider value={{ showData, loading, error, setShowData, setLoading, setError }}>
      {children}
    </ShowContext.Provider>
  );
};

export const useShowContext = () => {
  const context = useContext(ShowContext);
  if (context === undefined) {
    throw new Error('useShowContext must be used within a ShowProvider');
  }
  return context;
}; 