import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import showService from '@/services/showService';
import type { AutoShow } from '@/services/types/survey';
import { AxiosError } from 'axios';

export const useShowData = (
  hashed_show_id: string,
  specialist_id: string,
  timestamp: string,
  qrTime: moment.Moment,
  skipFetch: boolean = false
) => {
  const navigate = useNavigate();
  const [showData, setShowData] = useState<AutoShow | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const loadShowData = async () => {
      if (skipFetch) {
        setLoading(false);
        return;
      }

      try {
        const data = await showService.fetchShow({
          hashed_show_id,
          specialist_id: parseInt(specialist_id, 10),
          timestamp,
          qrTime: qrTime.toISOString()
        });

        if (!isMounted) return;

        if (data.status === 'after') {
          navigate('/oops', { 
            state: { message: 'This show has ended.' }
          });
          return;
        }

        setShowData(data);
      } catch (err) {
        if (!isMounted) return;
        console.error('Error loading show data:', err);
        setError(err as AxiosError);
        navigate('/oops');
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadShowData();

    return () => {
      isMounted = false;
    };
  }, [hashed_show_id, specialist_id, timestamp, qrTime.valueOf(), navigate, skipFetch]);

  return { showData, loading, error };
}; 