import React from 'react';
import { useRoutes } from 'react-router-dom';
import { LanguageProvider } from '@/contexts/LanguageContext';
import routes from '@/routes';

const App = () => {
  const element = useRoutes(routes);
  
  return (
    <LanguageProvider>
      {element}
    </LanguageProvider>
  );
};

export default App;
