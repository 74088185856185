import client from './client';

export default class BaseApiService {
  protected client = client;
  protected basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  protected getUrl(path: string): string {
    return `${this.basePath}${path}`;
  }

  protected async get<T>(path: string, params?: object): Promise<T> {
    return this.client.get(this.getUrl(path), { params });
  }

  protected async post<T>(path: string, data?: object): Promise<T> {
    return this.client.post(this.getUrl(path), data);
  }

  protected async put<T>(path: string, data?: object): Promise<T> {
    return this.client.put(this.getUrl(path), data);
  }

  protected async delete<T>(path: string): Promise<T> {
    return this.client.delete(this.getUrl(path));
  }
} 