import { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import { AxiosError } from 'axios';
import moment from 'moment';
import Hashids from 'hashids';

import { useShowData } from '@/hooks/useShowData';
import { useLanguage } from '@/contexts/LanguageContext';
import { useShowContext } from '@/contexts/ShowContext';
import FordLogo from '@/assets/images/Ford_Signature_Blue.png';
import LincolnLogo from '@/assets/images/Lincoln_Logo_Black.png';
import LincolnStarLogo from '@/assets/images/Lincoln_Star_White.png';
import '@/styles/language-switcher.scss';
import '@/styles/footer.scss';
import { useTranslation } from '@/hooks/useTranslation';

declare global {
  interface Window {
    Optanon?: {
      ToggleInfoDisplay?: () => void;
    };
  }
}

const Layout = () => {
  const { t } = useTranslation();
  const { setShowData, setLoading, setError } = useShowContext();
  const { language, setLanguage } = useLanguage();
  const { hashed_show_id, specialist_id, timestamp } = useParams();
  const [themeLoaded, setThemeLoaded] = useState(false);
  const location = useLocation();
  
  // Skip API call if we're on the success page and have state data
  const isSuccessPage = location.pathname === '/success';
  const hasStateData = location.state?.showData;

  // Get QR timestamp
  const hasher = new Hashids('stupid scammers');
  const [ts] = hasher.decode(timestamp!);
  const qrTime = moment(Number(ts));

  // Only fetch show data if we're not on success page with state data
  const { showData: apiShowData, loading, error } = useShowData(
    hashed_show_id!,
    specialist_id!,
    timestamp!,
    qrTime,
    isSuccessPage && hasStateData
  );

  // Use state data or API data
  const showData = (isSuccessPage && hasStateData) ? location.state.showData : apiShowData;

  // Update context with the appropriate data
  useEffect(() => {
    if (isSuccessPage && hasStateData) {
      setShowData(location.state.showData);
      setLoading(false);
      setError(null);
    } else {
      setShowData(apiShowData);
      setLoading(loading);
      setError(error instanceof AxiosError ? error : error ? new AxiosError(error) : null);
    }
  }, [showData, loading, error, setShowData, setLoading, setError, isSuccessPage, hasStateData, location.state]);

  // Load theme based on showData
  useEffect(() => {
    if (showData) {
      const brand = showData.isLincoln ? 'lincoln' : 'ford';
      
      // Remove previous theme styles
      const previousLinks = document.querySelectorAll('link[data-theme]');
      previousLinks.forEach(link => link.remove());

      // Dynamically import the appropriate CSS files
      Promise.all([
        import(`@/assets/styles/${brand}/styles/_variables.css`),
        import(`@/assets/styles/${brand}/styles/${brand}.css`),
        import(`@/assets/styles/${brand}/styles/gdux-${brand}.css`),
        import(`@/assets/styles/${brand}/fontFamilies/${brand}-font-families.css`)
      ]).then(() => {
        document.body.setAttribute('data-theme', brand);
        setThemeLoaded(true);
      }).catch((error) => {
        console.error('Error loading theme:', error);
        Sentry.captureException(error);
      });
    }
  }, [showData]);

  // Add Lincoln-specific class and font family when it's Lincoln
  useEffect(() => {
    if (showData) {
      // Set the brand-specific font family on the body
      document.body.style.fontFamily = showData.isLincoln 
        ? "'Lincoln Modern', 'Lincoln Proxima Nova', Arial, sans-serif"
        : "'FordF1', Arial, sans-serif";

      // Add/remove Lincoln-specific class
      if (showData.isLincoln) {
        document.body.classList.add('lincoln-survey');
      } else {
        document.body.classList.remove('lincoln-survey');
      }
    }

    // Cleanup
    return () => {
      document.body.style.fontFamily = '';
      document.body.classList.remove('lincoln-survey');
    };
  }, [showData]);

  const openCookieSettings = () => {
    const { Optanon } = window;
    Optanon?.ToggleInfoDisplay?.();
  };

  if (loading || !showData || !themeLoaded) return <Outlet />;

  return (
    <div className={`min-h-screen ${showData.isLincoln ? 'font-lincoln' : 'font-ford'}`}>
      <header className="flex justify-between items-center px-4 py-2 md:px-6 md:py-3 bg-[var(--semantic-color-fill-onlight-brand-strongest)]">
        <img 
          src={showData.isLincoln ? LincolnLogo : FordLogo}
          alt={`${showData.isLincoln ? 'Lincoln' : 'Ford'} logo`}
          className={`${
            showData.isLincoln 
              ? 'h-10 md:h-12' 
              : 'h-8'
          } ${showData.isLincoln ? 'brightness-0 invert' : 'brightness-0 invert'}`}
        />

        {/* Language Switcher */}
        {showData && (
          <div className="language-switcher">
            <button
              className={language === 'en' ? 'active' : ''}
              onClick={() => setLanguage('en')}
            >
              EN
            </button>
            <button
              className={language !== 'en' ? 'active' : ''}
              onClick={() => setLanguage(showData.isCanadian ? 'fr' : 'es')}
            >
              {showData.isCanadian ? 'FR' : 'ES'}
            </button>
          </div>
        )}
      </header>

      {/* Main content */}
      <main className="flex-grow">
        <Outlet />
      </main>

      {/* Footer - only show if not Canadian */}
      {showData && !showData.isCanadian && (
        <footer className={`mt-auto ${showData.isLincoln ? 'bg-[#1C1C1C] pt-6' : ''}`}>
          {!showData.isLincoln && (
            <hr className="border-t border-gray-300 my-6" />
          )}
          
          <div className="px-8 md:px-12 pb-8 flex flex-col md:flex-row md:items-center">
            <div className="flex flex-col md:flex-row md:items-center md:gap-6 md:flex-1">
              {showData.isLincoln ? (
                <div className="flex flex-col md:flex-row md:items-center">
                  <span className="text-[11px] uppercase tracking-wider text-white/70 mt-8 mb-8 md:my-0">
                    © {new Date().getFullYear()} Ford Motor Company
                  </span>
                  <span className="hidden md:inline text-white/70 mx-6">|</span>
                  <a 
                    href={language === 'en' 
                      ? 'https://www.lincoln.com/help/privacy-terms/#privacy'
                      : 'https://es.lincoln.com/help/privacy-terms/#privacy'}
                    target="_blank"
                    rel="noopener"
                    className="text-[11px] uppercase tracking-wider text-white/70 hover:text-white hover:underline decoration-[#f15a22] underline-offset-4 mb-8 md:mb-0"
                  >
                    {t('privacyNotice')}
                  </a>
                  <span className="hidden md:inline text-white/70 mx-6">|</span>
                  <button 
                    onClick={openCookieSettings}
                    className="text-[11px] uppercase tracking-wider text-white/70 hover:text-white hover:underline decoration-[#f15a22] underline-offset-4 mb-8 md:mb-0 text-left"
                  >
                    {t('cookieSettings')}
                  </button>
                  <span className="hidden md:inline text-white/70 mx-6">|</span>
                  <a 
                    href={language === 'en'
                      ? 'https://www.lincoln.com/help/privacy-terms/#caPrivacy'
                      : 'https://es.lincoln.com/help/privacy-terms/#caPrivacy'}
                    target="_blank"
                    rel="noopener"
                    className="text-[11px] uppercase tracking-wider text-white/70 hover:text-white hover:underline decoration-[#f15a22] underline-offset-4 flex items-center gap-2 mb-8 md:mb-0"
                  >
                    {t('privacyChoices')}
                    <img 
                      src="https://cdn.latitudewebservices.com/icons/ccpa-icon-ford.png" 
                      alt="Privacy Choices Icon" 
                      className="h-4 w-auto"
                    />
                  </a>
                  <span className="hidden md:inline text-white/70 mx-6">|</span>
                  <a 
                    href={language === 'en'
                      ? 'https://www.lincoln.com/help/privacy/#USprivacypolicy'
                      : 'https://es.lincoln.com/help/privacy/#USprivacypolicy'}
                    target="_blank"
                    rel="noopener"
                    className="text-[11px] uppercase tracking-wider text-white/70 hover:text-white hover:underline decoration-[#f15a22] underline-offset-4 mb-12 md:mb-0"
                  >
                    {t('interestBasedAds')}
                  </a>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row md:items-center md:gap-6">
                  <span className="text-sm text-gray-600 mt-8 mb-4 md:my-0">
                    © {new Date().getFullYear()} Ford Motor Company
                  </span>
                  <a 
                    href={language === 'en'
                      ? 'https://www.ford.com/help/privacy/'
                      : 'https://es.ford.com/help/privacy/'}
                    target="_blank"
                    rel="noopener"
                    className="text-sm text-gray-600 hover:text-gray-900 mb-4 md:mb-0"
                  >
                    {t('privacyNotice')}
                  </a>
                  <button 
                    onClick={openCookieSettings}
                    className="text-sm text-gray-600 hover:text-gray-900 mb-4 md:mb-0 text-left"
                  >
                    {t('cookieSettings')}
                  </button>
                  <a 
                    href={language === 'en'
                      ? 'https://www.ford.com/help/privacy/ccpa/'
                      : 'https://es.ford.com/help/privacy/ccpa/'}
                    target="_blank"
                    rel="noopener"
                    className="text-sm text-gray-600 hover:text-gray-900 flex items-center gap-2 mb-4 md:mb-0"
                  >
                    {t('privacyChoices')}
                    <img 
                      src="https://cdn.latitudewebservices.com/icons/ccpa-icon-ford.png" 
                      alt="Privacy Choices Icon" 
                      className="h-4 w-auto"
                    />
                  </a>
                  <a 
                    href={language === 'en'
                      ? 'https://www.ford.com/help/privacy/#USprivacypolicy'
                      : 'https://es.ford.com/help/privacy/#USprivacypolicy'}
                    target="_blank"
                    rel="noopener"
                    className="text-sm text-gray-600 hover:text-gray-900"
                  >
                    {t('interestBasedAds')}
                  </a>
                </div>
              )}
            </div>

            {showData.isLincoln && (
              <img 
                src={LincolnStarLogo}
                alt="Lincoln logo" 
                className="h-12 md:h-12 opacity-80 self-start md:self-auto"
              />
            )}

            {!showData.isLincoln && (
              <img 
                src={FordLogo}
                alt="Ford logo" 
                style={{ width: '90px', height: 'auto' }}
                className="opacity-80 mt-8 md:mt-0 md:ml-12"
              />
            )}
          </div>
        </footer>
      )}
    </div>
  );
};

export default Layout;
