import React from 'react';

const Oops = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full border rounded-lg p-8 text-center">
        <h1 className="text-2xl font-semibold mb-4">This link didn't work!</h1>
        <p className="text-gray-600">
          Please ask your product specialist to re-generate the QR code, and scan again.
        </p>
      </div>
    </div>
  );
};

export default Oops;
