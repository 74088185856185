import React from 'react';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

// Initialize Sentry before importing any other components
Sentry.init({
  dsn: "https://16eb89402cb4638e7fe080dce181fcbc@o4506238718967808.ingest.us.sentry.io/4508605627367424",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Import other dependencies after Sentry initialization
import App from '@/App';
import './index.css';
import '@/assets/scss/style.scss';
import 'survey-core/defaultV2.min.css';

const container = document.getElementById('root');
const root = createRoot(container!);
const app = (
  <HashRouter>
    <App />
  </HashRouter>
);
root.render(app);
